
<template>
  <div>
    <layout>
      <div class="search-top">
        <div class="contant">
          <div class="contant-top">
            <div class="contant-logo">
              <img src="@/assets/home/logo.png" alt />
              <span>爱玩就上爱租号</span>
            </div>
            <div class="contant-search">
              <input type="text" placeholder="搜你想要的~" v-model="valueList" />
              <button @click="goSerach">搜索</button>
            </div>
          </div>
          <div class="contant-bottom">
            <div class="contant-hot">
              <img src="@/assets/home/hot.png" alt />
              <span>热门游戏</span>
            </div>
            <ul>
              <li :class="{ active: isShow == 0 }" @click="toClick(0)">首页</li>
              <li :class="{ active: isShow == 1 }" @click="toClick(1)">
                爱租号
              </li>
              <li :class="{ active: isShow == 2 }" @click="toClick(2)">
                工具下载
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="order">
        <div class="order-contants">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }"
              >爱租号首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>商品详情</el-breadcrumb-item>
            <el-breadcrumb-item>确认订单</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="order-box card">
            <div class="order-top">
              <p>核对订单信息</p>
              <ul>
                <li>
                  <span class="li-span">1</span>
                  <span class="lispan">选择游戏</span>
                </li>
                <li>
                  <span class="li-span activeNew">2</span>
                  <span class="lispan activeNew1">核对订单</span>
                </li>
                <li>
                  <span class="li-span activeOld">3</span>
                  <span class="lispan">成功付款</span>
                </li>
                <li>
                  <span class="li-span activeOld">4</span>
                  <span class="lispan">体验游戏</span>
                </li>
              </ul>
              <div class="line"></div>
            </div>
            <div class="order-info">
              <div class="info-left">商品信息</div>
              <div class="info-right">
                <div class="info-left">起租时长</div>
                <div class="info-left">单价</div>
                <div class="info-left">押金</div>
              </div>
            </div>
            <div class="order-contant">
              <div class="contant-top">
                <div class="contant-img">
                  <img :src="info.game_img || zhwList.game_img" alt />
                  <div class="contant-title">
                    <p>
                      {{
                        detail.goods_title ||zhwList.pn
                      }}
                    </p>
                    <span>
                      {{
                        detail.game_all_name ||`${zhwList.game_name}-${zhwList.game_server_name}-${zhwList.game_zone_name}`
                      }}</span
                    >
                    <span>角色名：{{ detail.role_name ||zhwList.jsm }}</span>
                  </div>
                </div>

                <div class="date-div" v-if="!zhwList.pn">
                  <ul>
                    <li :class="{dateActive: currentMeony == item.type}" v-for="item in lease_type" :key="item.type" @click="selcetMeony(item.isshow,item)">
                      <p v-if="item.isshow">¥{{item.price}}</p><p v-else>-</p>			  
                      <span>{{item.name}}</span>
                    </li>
                  </ul>
                </div>
                <div class="date-div" v-else>
                  <ul>
                    <li :class="{dateActive: currentMeony == item.type}" v-for="item in lease_type" :key="item.type" @click="selcetMeony(item.isshow,item)">
                      <p>¥{{item.price}}</p>		  
                      <span>{{item.name}}</span>
                    </li>
                  </ul>
                </div>

                <div class="contant-type">
                  <div class="type-title">租赁类型</div>
                  <div class="type-moneny" v-if="isOk">
                    <p>¥ {{ nums }}</p>
                    <div>
                      <span>时租</span>最低{{ detail.short_lease ||zhwList.szq}}小时起租
                    </div>
                  </div>
                  <div class="type-moneny" v-else>
                    <p>¥ {{nums}}</p>
                    <div>
                      您选择<span>{{ times }}</span>租号
                    </div>
                  </div>

                </div>
                <div class="contant-btn" v-if="isOk">
                  <div class="btn-title">租赁数量</div>
                  <div class="sub" @click="sub">_</div>
                  <div class="btn-input">{{ num }}</div>
                  <div class="add" @click="add">+</div>
                </div>
              </div>
              <div class="contant-right">
                <div class="contant-left">{{ detail.short_lease ||zhwList.szq}}小时</div>
                <div class="contant-left">{{ detail.lease_price||zhwList.pmoney }}/小时</div>
                <div class="contant-left" v-if="!bzmoney">{{ `${detail.foregift}`}}元</div>
                <div class="contant-left" v-else>{{bzmoney }}元</div>
              </div>
            </div>
            <div class="order-bottom">
              <div class="bottom-left">
                <div class="select-top">
                  <p>可用优惠券：</p>
                  <el-select v-model="value" clearable placeholder="选择优惠券" @change="valueAll" @clear='clearValue' no-data-text="无可用优惠券">
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.money"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <div class="select-title">
                  <img src="../../assets/order/tx.png" alt />
                  <p>
                    温馨提醒：使用优惠券订单15分钟内维权核实为非租方问题可退还券，超过15分钟不再退还券！<br>
                    某些出租商品设置需要缴纳押金才能租赁，您租号的押金会在48小时后返还到您的账户！
                    <span>
                    </span>
                  </p>
                </div>
              </div>
              <div class="bottom-right">
                <div class="common-class">
                  <p>订单金额：</p>
                  <span>¥{{ nums }}</span>
                </div>
                <div class="common-class">
                  <p><img src="../../assets/order/ts.png" alt /> 商品押金：</p>
                  <span v-if="!bzmoney">¥{{ `${detail.foregift}`}}</span>
                  <span v-else>¥{{ bzmoney }}</span>
                </div>
                <div class="txt">
                  <p>实付总额:</p>
                  <span>¥{{ this.combined }}</span>
                </div>
                <div class="btn">
                  <el-button size="small" round type="primary"
                    @click="clickShow">去付款</el-button
                  >
                </div>
                <div class="bottom-img">
                  <img src="../../assets/order/gx.png" alt />
                  <p @click="$router.push({name: 'service'})">我已阅读并同意《爱租号服务协议》</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </layout>
    <!-- 订单确认 -->
    <div class="tips" v-if="orShow">
      <div class="tips-box card">
        <div class="tips-top">
          <div></div>
          <p>订单支付确认</p>
          <img src="../../assets/order/x.png" alt  @click="clickShow"/>
        </div>
        <ul>
           <li v-for="(item,index) in prototypelist" :key="index">
                  {{ item.key_name || item.key_proto }}:
                  {{ item.value || item.val }}
                </li>
        </ul>
        <div class="tips-title">
          <p>!</p>
          <span
            >温馨提醒：段位等级可能随着其它租客体验会有较小差异，在不影响游戏体验的情况下请不要随意投诉哦~</span
          >
        </div>
        <div class="tips-bottom">
          <el-button type="primary" size="mini" plain round @click="clickShow">取消</el-button>
          <el-button type="primary" size="mini" round @click="clickTop">确认支付</el-button>
        </div>
      </div>
    </div>
    <!-- 订单支付 -->
    <div class="tips" v-show="toShow">
      <div class="tips-box card">
        <div class="tips-top">
          <div></div>
          <p>订单支付</p>
          <img src="../../assets/order/x.png" alt @click="clickShow"/>
        </div>
        <div class="tips-p">本次订单需充值金额</div>
        <div class="tips-color">
          <div class="color-money">
            ¥
            <span>{{ this.combined }}</span>
          </div>
          <div class="color-span">
            <span>租金：{{ this.combined }}元</span>
            <span class="spans">|</span>
            <span v-if="!bzmoney">押金：{{ `${detail.foregift}`}}元</span>
            <span v-else>押金：{{bzmoney}}元</span>
          </div>
        </div>
        <div class="tips-price">
          <p>当前余额：</p>
          <span>{{ext_cash}}元</span>
        </div>
        <div class="tips-type">
          <p>充值方式：</p>
          <div class="tips-border " :class="{activeTips: curretMoney == 0}" @click="toclick(0)">
            <img src="../../assets/order/zfb.png" alt />
          </div>
          <div class="tips-border" :class="{activeTips: curretMoney == 1}" @click="toclick(1)">
            <img src="../../assets/order/wx.png" alt />
          </div>
          <div class="tips-border" :class="{activeTips: curretMoney == 2}" @click="toclick(2)" v-if="false">
            <img src="../../assets/order/myhb.png" alt />
          </div>
        </div>
        <div class="tips-code">
          <p>二维码:</p>
          <div  v-loading="loading">
              <div id="qrCode" ref="qrCodeDiv"></div>
          </div>
        </div>
        <div class="tips-txt">
          <img src="../../assets/order/tx.png" alt />
          <p>温馨提醒：如未及时到账，请1-3分钟联系客服</p>
        </div>
      </div>
    </div>
    <!-- 提示 -->
    <div class="tips" v-if="false">
      <div class="tips-box card">
        <div class="tips-top">
          <p>提示信息</p>
          <img src="../../assets/order/x.png" alt />
        </div>
        <div class="tips-center">请选择预约时间</div>
        <div class="tips-btn">
          <el-button size="medium" round type="primary">确定</el-button>
        </div>
      </div>
    </div>

    <!-- 租号失败 -->
    <div class="tips" v-if="false">
      <div class="tips-box card">
        <div class="tips-top">
          <div></div>
          <p>提示</p>
          <img src="../../assets/order/x.png" alt />
        </div>
        <div class="tips-succse">
          <div class="succse-top">
            <p>租号失败，该账号已被租用</p>
            <span
              >下单支付的金额已返还，当前余额：0.00元，若有疑问可联系客服</span
            >
          </div>
          <div class="succse-center">
            <div class="succse-left">
              <img src="../../assets/order/ysc.png" alt />
              <span>收藏成功</span>
            </div>
            <div class="succse-right">
              <el-button size="mini" round plain type="primary"
                >在线客服</el-button
              >
              <el-button size="mini" round type="primary">查看余额</el-button>
            </div>
          </div>
          <div class="succse-bottom">
            <div class="recommend">
              <p>同区精选账号推荐</p>
              <span>
                更多账号
                <i class="el-icon-caret-right"></i>
              </span>
            </div>
            <div class="contant-scroll">
              <div class="continer">
                <div>
                  <img src="../../assets/order/zfcg.png" alt />
                </div>
                <div class="continer-right">
                  <div class="continer-title">
                    182款皮肤，艾琳女武神I幻海之心I辉光之辰如之辰如
                  </div>
                  <p>出租次数1234次</p>
                  <span>5.8元/小时</span>
                </div>
              </div>
              <div class="continer">
                <div>
                  <img src="../../assets/order/zfcg.png" alt />
                </div>
                <div class="continer-right">
                  <div class="continer-title">
                    182款皮肤，艾琳女武神I幻海之心I辉光之辰如之辰如
                  </div>
                  <p>出租次数1234次</p>
                  <span>5.8元/小时</span>
                </div>
              </div>
              <div class="continer">
                <div>
                  <img src="../../assets/order/zfcg.png" alt />
                </div>
                <div class="continer-right">
                  <div class="continer-title">
                    182款皮肤，艾琳女武神I幻海之心I辉光之辰如之辰如
                  </div>
                  <p>出租次数1234次</p>
                  <span>5.8元/小时</span>
                </div>
              </div>
              <div class="continer">
                <div>
                  <img src="../../assets/order/zfcg.png" alt />
                </div>
                <div class="continer-right">
                  <div class="continer-title">
                    182款皮肤，艾琳女武神I幻海之心I辉光之辰如之辰如
                  </div>
                  <p>出租次数1234次</p>
                  <span>5.8元/小时</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 身份认证 -->
    <div class="tips" v-if="false">
      <div class="tips-box card" style="width: 36rem">
        <div class="tips-top">
          <p class="id-card">身份认证</p>
          <img src="../../assets/order/x.png" alt />
        </div>
        <div class="id-title">
          <p>
            一、根据法律法规要求，游戏玩家需先进行实名认证，方可进行相关交易。
          </p>
          <p>
            二、为确保您顺畅的租号体验，请尽快完成下方的实名认证，爱租号平台将对您的信息严格保密。
          </p>
        </div>
        <div class="tips-top">
          <p class="id-card">请完善您的身份证信息</p>
        </div>
        <div class="id-from">
          <div class="id-name">
            <img src="../../assets/order/xh.png" alt="" />
            <span>真实姓名：</span>
            <input type="text" />
            <img class="id-img" src="../../assets/order/yzcg.png" alt="" />
          </div>
          <div class="id-p">
            注意：该姓名需和绑定支付宝姓名一致，否则无法体现成功！
          </div>
          <div class="id-name">
            <img src="../../assets/order/xh.png" alt="" />
            <span>居民身份证号码：</span>
            <input type="text" />
            <img class="id-img" src="../../assets/order/yzcg.png" alt="" />
          </div>
        </div>
        <div class="id-txt">
          <span>*</span>
          <p>实名认证完成后，若您的身份未满18岁，仍然禁止发布账号、下单操作</p>
        </div>
        <div class="id-txt">
          <span>*</span>
          <p>身份证信息只能提交一次，不能随意修改，请慎重填写。</p>
        </div>
        <div class="id-btn">
          <el-button type="primary" round>确定提交</el-button>
        </div>
      </div>
    </div>
      <!-- 电梯导航 -->
    <side-Bar></side-Bar>
  </div>
</template>
 
<script>
import sideBar from "../../components/common/sidebar";

import QRCode from 'qrcodejs2'
import layout from "@/components/layout";

import qs from 'qs' 

export default {
  name: "order",
  data() {
    return {
      type:1,
      valueList: '',
      nums: '',
      times: '',
      isOk: true,
      lease_type: [],
      currentMeony:1,

      order_no: '',
      myInterval: null,
      prototypelist: [],
      loading: true,//loading 状态
      curretMoney: 0,
      orShow: false,//确认订单
      toShow: false, //余额不足
      isShow: 1,
      info: [],
      num: 1, //时长
      deposit: "", //押金
      money: "", //价格
      combined: 0, //合计
      detail: {},
      hb_money:0, //优惠金额
      price: 0,
      snum: 1,
      goods_id: 0,
      hb_id :0,  //红包id
      // 下拉选择优惠券
      options: [
        // {id:1,money:5}
      ],
      value: "",
      //   身份绑定
      ext_cash: '',

      oK: false,


      zhwList:{},
      bzmoney:''
    };
  },
  components: {
    layout,
    sideBar
  },
  created() {
    this.ext_cash= sessionStorage.getItem('ext_cash')

    // 租号玩
    if(this.$route.params.id.indexOf("&") == -1) {
        this.goods_id = this.$route.params.id;
        this.getRequest();
    }else{
      this.zhwList = qs.parse(this.$route.params.id)
      this.bzmoney = this.zhwList.bzmoney
      this.nums = this.zhwList.pmoney
      this.num = this.zhwList.szq
      this.newList()
      this.price = this.nums
      this.gethbNum();
    }
   
  },
  methods: {
    toClick(id) {
      if (id == 0) {
        this.$router.push({
          path: "/home",
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/download",
        });
      }
      this.isShow = id;
    },
    // 身份验证的
    // 获取订单详情
    getRequest() {
      this.$get("api/account_detail", { account_id: this.goods_id }).then(
        (res) => {
          if (res.code == 0) {
            console.log(res, '详情');
            this.nums = res.ret.detail.lease_price
            this.lease_type = res.ret.lease_type;

            this.prototypelist = res.ret.prototypelist;
            this.info = res.ret;
            this.detail = res.ret.detail;
            this.num = res.ret.detail.short_lease;
            this.deposit = res.ret.detail.foregift || 0;
            this.money = res.ret.detail.lease_price || 0;
            this.combined = (parseFloat(this.money) * this.num + parseFloat(this.deposit) - parseFloat(this.hb_money)).toFixed(2);
            this.price = (parseFloat(this.money) * this.num +parseFloat(this.deposit)).toFixed(2);
            this.gethbNum();
          }else{
            console.log(res.message);
          }
        }
      );
    },
    gethbNum() {
      this.$get("api/user/giftlist", { money: this.price }).then((res) => {
        if (res.code == 0) {
          console.log(res,'优惠');
          if(res.ret.length==0){
            this.oK = true
          }
          this.options = res.ret.list
          console.log(this.oK);
        }
      });
    },
    // 减
    sub() {
      if(this.combined == 0) return
      
      if(this.$route.params.id.indexOf("&") == -1) {
         if (this.num <= this.detail.short_lease) {
            this.num = this.detail.short_lease;
          } else {
            
            this.num--;
            let newnum = parseFloat(this.money) * this.num + parseFloat(this.deposit) 
            if(newnum < parseFloat(this.hb_money)){
                this.combined = "0.00"
            }else{
                this.combined = (newnum -parseFloat(this.hb_money)).toFixed(2);
            }
          
            this.price = parseFloat(this.money) * this.num +parseFloat(this.deposit)
            this.gethbNum();
            if(this.oK) {
              this.clearValue()
            }
          }
      }else{
         if (this.num <= this.zhwList.szq) {
            this.num = this.zhwList.szq
          } else {
            this.num--;
            let newnum = parseFloat(this.nums) * this.num + parseFloat(this.zhwList.bzmoney) 
            if(newnum < parseFloat(this.hb_money)){
                this.combined = "0.00"
            }else{
                this.combined = (newnum -parseFloat(this.hb_money)).toFixed(2);
            }

            this.price = parseFloat(this.nums) * this.num +parseFloat(this.zhwList.bzmoney)
            this.gethbNum();
            if(this.oK) {
              this.clearValue()
            }
          }
      }    
    },
    // 加
    add() {
      if(this.$route.params.id.indexOf("&") == -1){
          this.num++;
            let newnum = parseFloat(this.money) * this.num +parseFloat(this.deposit) 
            if(newnum < parseFloat(this.hb_money)){
                this.combined = "0.00"
            }else{
                this.combined = (newnum -parseFloat(this.hb_money)).toFixed(2);
            }

          this.price = parseFloat(this.money) * this.num +parseFloat(this.deposit)
          this.gethbNum();
          if(this.oK){
            this.clearValue()
          }
      }else{
          this.num++;
            let newnum = parseFloat(this.nums) * this.num + parseFloat(this.zhwList.bzmoney) 
            if(newnum < parseFloat(this.hb_money)){
                this.combined = "0.00"
            }else{
                this.combined = (newnum -parseFloat(this.hb_money)).toFixed(2);
            }

          this.price = parseFloat(this.nums) * this.num +parseFloat(this.zhwList.bzmoney)
          this.gethbNum();
          if(this.oK){
            this.clearValue()
          }
      }
      
    },
    // 有余额支付事件
    clickTop() {
      if(this.$route.params.id.indexOf("&") == -1){
         this.$post('api/order/pay', { goods_id: this.goods_id,game_time:this.num,hb_id:this.hb_id,type:this.type,}).then(res => {
            if (res.code == 0) {
              // console.log(res,'ord123');
            this.$router.push({
                path: `/succese/${res.ret.order_no}`
              })
            }else{
              if(res.code==20004){
                this.orShow = false
                this.toShow = true
                this.clickPath()
              }else{
                this.$message.error(res.message);
                this.orShow = false
              }
            }
          })
      }else{
          let datas = {
              status: 1,
              msg: "",
              data: {
                count: 2000,
                list:[this.zhwList]
              }
          }
           this.$post('api/order/pay', { goods_id: this.zhwList.id,game_time:this.num,hb_id:this.hb_id,type:this.type,channel:2,order_details:JSON.stringify(datas)}).then(res => {
            if (res.code == 0) {
            this.$router.push({
                path: `/succese/${res.ret.order_no}`
              })
            }else{
              if(res.code== 77708){
                this.orShow = false
                this.toShow = true
                this.clickPath()
              }else{
                this.$message.error(res.message);
                this.orShow = false
              }
            }
          })
      }
     
    },
    // 选择红包
    valueAll() {
      const combineds = this.combined
      this.options.filter((item) => {
        if(item.id == this.value) {
          this.hb_money = item.money
        }
      })
      if(this.$route.params.id.indexOf("&") == -1){
        if(this.hb_money > parseFloat(combineds)) {
           this.combined = "0.00"
           return
        }
        this.combined = (parseFloat(combineds)- parseFloat(this.hb_money)).toFixed(2);
        this.hb_id = this.value
        if(this.currentMeony != 1){
          this.combined = (parseFloat(this.nums)- parseFloat( this.hb_money)).toFixed(2)
        }
      }else{
        if(this.hb_money > parseFloat(combineds)) {
           this.combined = "0.00"
           return
        }
         if(this.currentMeony != 1){
          this.combined = (parseFloat(this.nums)- parseFloat( this.hb_money)).toFixed(2)
          return
        }
        this.combined = (parseFloat(this.nums) * this.num + parseFloat(this.zhwList.bzmoney) - parseFloat(this.hb_money)).toFixed(2);
        
      }
      
    },
    clearValue() {
      this.value = ''
       if(this.$route.params.id.indexOf("&") == -1){
          if(this.currentMeony == 1){
            this.hb_money = 0
            this.combined = (parseFloat(this.money) * this.num + parseFloat(this.deposit)- parseFloat(this.hb_money)).toFixed(2)
          }else{
            this.combined = this.nums
          }
       }else{
          if(this.currentMeony == 1){
            this.hb_money = 0
            this.combined = (parseFloat(this.nums) * this.num + parseFloat(this.zhwList.bzmoney) - parseFloat(this.hb_money)).toFixed(2);
          }else{
            this.combined = this.nums
          }
       }
      
    },
    // 确定支付弹框
    clickShow() {
      this.orShow = !this.orShow
      this.toShow = false
      clearInterval(this.myInterval)
    },
    // 切换支付方式
    toclick(id) {
      if(id == 1) {
        this.snum = 2
        this.loading = true
        this.clickPath (id)
      }else{
        this.snum = 1
        this.loading = true  
        this.clickPath (id)
      }
    },

    // 二维码url的
     clickPath (id = 0) {
      this.$post('api/recharge/pay', {amount:this.combined,type:this.snum,channel_type:3,goods_id:this.goods_id}).then(res => {
        if (res.code == 0) {
          var url = res.ret.qrCode
          this.order_no = res.ret.order_no
          this.qrcode(url)
          this.curretMoney = id
        //处理轮询查询订单
          this.myInterval = window.setInterval(() => {
            setTimeout(this.orderQuery(), 0);
          }, 2000);
       }else{
         this.$message.error(res.message)
         this.loading = false
       }
      })
    },
     orderQuery() {
      var that = this
      that.$post("api/recharge/order_query", {order_no:that.order_no }).then(
        (res) => {
          if (res.code == 0) {
              if (res.ret) {
                //处理支付成功的逻辑
                that.$router.push({
                 path: `/succese/${that.order_no}`
               })
            }
          }
        }
      );
    },
    // 生成二维码
     qrcode (text) {
      this.loading = false
      document.getElementById('qrCode').innerHTML = ''
      setTimeout(() => {
        new QRCode(this.$refs.qrCodeDiv, {
          text: text,
          width: 158,
          height: 158,
          colorDark: '#333333', 
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
        }, 100)
      })
    },
     goSerach() {
        if(this.valueList){
            this.$router.push({
            path: '/lobby',
            query: {
            key_word: this.valueList
            }
          })
        }
      
    },
     // 选择套餐模式
    selcetMeony(isshow,item) {
    if(this.$route.params.id.indexOf("&") == -1){
      if(!isshow) return;
      this.currentMeony = Number(item.type)
      this.nums = item.price
      this.times = item.name
      this.type = item.type
      if(this.type != 1){
        this.isOk = false
        this.combined = this.nums
        this.price = item.price
        this.gethbNum()
        this.value = ''
      }else{
        this.isOk = true
        this.hb_money = 0
        this.value = ''
        this.combined = (parseFloat(this.money) * this.num + parseFloat(this.deposit) - parseFloat(this.hb_money)).toFixed(2);
         this.price = (
          parseFloat(this.money) * this.num +
          parseFloat(this.deposit)
        ).toFixed(2);
        this.gethbNum();
      }
    }else{
        this.currentMeony = Number(item.type)
        this.nums = item.price
        this.times = item.name
      if(this.currentMeony != 1){
        this.isOk = false
        this.combined = (parseFloat(this.nums)+parseFloat(this.zhwList.bzmoney)).toFixed(2);
        this.num = this.currentMeony
        this.price = item.price
        this.gethbNum()
        this.value = ''
      }else{
        this.isOk = true
        this.hb_money = 0
        this.num = this.zhwList.szq
        this.value = ''
        this.combined = (parseFloat(this.nums) * this.num + parseFloat(this.zhwList.bzmoney) - parseFloat(this.hb_money)).toFixed(2);
        this.price = item.price
        this.gethbNum();
      }
    }
		
    },

    // 租号玩
  newList() {
      let p1 = {}
      let p2 = {}
      let p3 = {}
      let p4 = {}
      p1.name = '时租'
      p1.price = this.zhwList.pmoney
      p1.type = 1
      p2.name = '日租'
      p2.price = this.zhwList.p24
      p2.type = 24
      p3.name = '十小时租'
      p3.price = this.zhwList.p10
      p3.type = 10
      p4.name = '周租'
      p4.price = this.zhwList.p168
      p4.type = 168
      let arr = [p1,p2,p3,p4]
      this.lease_type = arr

      this.combined = (parseFloat(this.nums) * this.num + parseFloat(this.zhwList.bzmoney) - parseFloat(this.hb_money)).toFixed(2);
      this.price = parseFloat(this.nums) * this.num + parseFloat(this.zhwList.bzmoney)
      this.gethbNum();
  }

  },
    // 页面切换
destroyed(){
  clearInterval(this.myInterval)
},



};
</script>
<style lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 30px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
        .active:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}

.order {
  width: 100%;
  padding-bottom: 66px;
  background-color: #f0f2f5;
  .order-contants {
    width: 1200px;
    margin: 0 auto;
    .el-breadcrumb {
      padding: 25px 0;
    }
    .order-box {
      padding: 0 56px 42px;
      .order-top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 142px;
        p {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
        ul {
          display: flex;
          align-items: center;
          position: absolute;
          top: 41%;
          right: 0;
          li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 63px;
            color: #83899d;
            .li-span {
              width: 20px;
              height: 20px;
              line-height: 20px;
              border-radius: 50%;
              background: #3c7efe;
              display: block;
              text-align: center;
              color: #fff;
              margin-bottom: 15px;
            }
            .activeNew {
              border: 4px solid #ccddff;
              width: 25px;
              height: 25px;
            }
            .activeNew1 {
              color: #3c7efe;
            }
            .activeOld {
              background: #9ebefe;
            }

            .lispan {
              font-size: 12px;
            }
          }
        }
        .line {
          width: 344px;
          margin-right: 20px;
          border-bottom: 1px dashed #9dbefe;
        }
      }
      .order-info {
        width: 100%;
        height: 50px;
        background: #f6f7fa;
        border-radius: 12px 12px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        .info-left {
          width: 90px;
          font-size: 14px;
          color: #848a9e;
        }
        .info-right {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 500px;
        }
      }
      .order-contant {
        width: 100%;
        height: 380px;
        padding: 25px 0 0 20px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #f3f3f3;
        margin-bottom: 16px;
        .contant-top {
          .contant-img {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
              width: 98px;
              height: 98px;
              border-radius: 12px;
              margin-right: 20px;
            }
            .contant-title {
              width: 404px;
              display: flex;
              flex-direction: column;
              p {
                font-size: 14px;
                color: #000000;
                line-height: 24px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              span {
                font-size: 12px;
                color: #848a9e;
                margin-top: 10px;
              }
            }
          }

          .date-div {
          margin: 15px 0 0;
          ul {
            display: flex;
            align-items: center;
            li {
              width: 102px;
              height: 70px;
              border-radius: 8px;
              text-align: center;
              background:rgba($color: #60C8FD, $alpha: .15);  
              margin-right: 10px;   
              display: flex;
              flex-direction: column;
              justify-content: center; 
              cursor: pointer;
              p {
                font-size: 20px;
                color: #3c7efe;
              }
              span {
                margin-top: 5px;
                font-size: 15px;
                color: #343434;
              }
            }
            .dateActive {
              background: linear-gradient(135deg, #60c8fd 0%, #7ea2fb 100%);
              p{
                color: #fff;
              }
              span{
              color: #fff;
              }
            }
          }
          }
          .contant-type {
            display: flex;
            align-items: center;
            margin: 35px 0 15px;
            .type-title {
              font-size: 15px;
              color: #848a9e;
              margin-right: 20px;
            }
            .type-moneny {
              width: 200px;
              height: 62px;
              border: 2px solid #3c7efe;
              border-radius: 12px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                bottom: -1px;
                right: -1px;
                // 定义元素宽高
                width: 36px;
                height: 34px;
                background: url(../../assets/order/czgx1.png);
                background-size: 100% 100%;
              }
              p {
                font-size: 20px;
                color: #fc3838;
              }
              div {
                font-size: 12px;
                color: #848a9e;
                span {
                  color: #000;
                }
              }
            }
          }
          .contant-btn {
            display: flex;
            align-items: center;
            .btn-title {
              font-size: 15px;
              color: #848a9e;
              margin-right: 20px;
            }
            .sub {
              width: 30px;
              height: 30px;
              background: #fafafb;
              border: 1px solid #c4c8d4;
              border-radius: 4px;
              line-height: 20px;
              text-align: center;
              cursor: pointer;
            }
            .btn-input {
              width: 82px;
              height: 30px;
              background: #ffffff;
              border: 1px solid #c4c8d4;
              border-radius: 4px;
              line-height: 30px;
              text-align: center;
              margin: 0 10px;
            }
            .add {
              width: 30px;
              height: 30px;
              background: #fafafb;
              border: 1px solid #c4c8d4;
              border-radius: 4px;
              line-height: 30px;
              text-align: center;
              cursor: pointer;
            }
          }
        }
        .contant-right {
          display: flex;
          justify-content: space-around;
          width: 500px;
          .contant-left {
            width: 90px;
            font-size: 14px;
            color: #848a9e;
          }
        }
      }
      .order-bottom {
        width: 100%;
        height: 400px;
        background: #f6f7fa;
        border: 1px solid #f6f7fa;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        .bottom-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 300px;
          .select-top {
            display: flex;
            align-items: center;
            p {
              font-size: 13px;
              color: #848a9e;
            }
          }
          .select-title {
            display: flex;
            // align-items: center;
            align-items: flex-start;
            img {
              width: 14px;
              height: 14px;
              margin-right: 5px;
              margin-top: 2px;
            }
            p {
              font-size: 12px;
              color: #a2a6b8;
            }
          }
        }
        .bottom-right {
          width: 275px;
          .common-class {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: #000000;
            margin-top: 12px;
            p {
              text-align: right;
              width: 103px;
              img {
                width: 12px;
                height: 12px;
                margin-right: 5px;
              }
            }
          }
          .txt {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 42px 0 40px;
            p {
              font-size: 15px;
              color: #000000;
              text-align: right;
              width: 95px;
            }
            span {
              font-size: 20px;
              color: #fc3838;
            }
          }
          .btn {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 12px;
          }
          .bottom-img {
            display: flex;
            align-items: center;
            img {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
            p {
              font-size: 12px;
              color: #848a9e;
            }
          }
        }
      }
    }
  }
}
//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
}

.id-card {
  padding-left: 13px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0px;
    width: 4px;
    height: 20px;
    background: #3c7efe;
    border-radius: 2px;
  }
}

//
.tips {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .tips-box {
    width: 24rem;
    background: #ffffff;
    border-radius: 12px;
    padding: 0 22px;
    .tips-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      p {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
      }
      img {
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
    // 订单确认
    ul {
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        width: 50%;
        // text-align: center;
        text-indent: 1em;
        margin-bottom: 20px;
        overflow: hidden; //隐藏文字
              text-overflow: ellipsis; //显示...
              white-space: nowrap; //不换行
        p {
          font-size: 13px;
          color: #848a9e;
          margin-right: 8px;
        }
        span {
          font-size: 14px;
          color: #000000;
        }
      }
    }
    .tips-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;
      p {
        width: 14px;
        height: 14px;
        border: 1px solid #fc3838;
        border-radius: 50%;
        color: #fc3838;
        text-align: center;
        line-height: 15px;
      }
      span {
        width: 318px;
        font-size: 12px;
        font-weight: 400;
        color: #fc3838;
      }
    }
    .tips-bottom {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 25px;
    }
    //订单支付
    .tips-p {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
    }
    .tips-color {
      height: 96px;
      background: #f6f7fa;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .color-money {
        font-size: 16px;
        color: #000000;
        span {
          font-size: 26px;
          font-weight: Bold;
        }
      }
      .color-span {
        font-size: 14px;
        color: #848a9e;
        .spans {
          margin: 0 20px;
        }
      }
    }
    .tips-price {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #848a9e;
      margin: 20px 0;
      span {
        color: #fc3838;
      }
    }
    .tips-type {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #848a9e;
      .activeTips::after {
        content: "";
        position: absolute;
        bottom: -1px;
        right: -1px;
        border: 1px solid #3c7efe;
        // 定义元素宽高
        width: 84px;
        height: 33px;
        border-radius: 6px;
        // background: url(../../assets/order/czgx1.png);
        background-size: 100% 100%;
      }
      .activeTips::before {
        content: "";
        position: absolute;
        bottom: -1px;
        right: -1px;
        // 定义元素宽高
        width: 20px;
        height: 20px;
        background: url(../../assets/order/czgx1.png);
        background-size: 100% 100%;
      }
      .tips-border {
        width: 84px;
        height: 33px;
        border: 1px solid #e1e4eb;
        border-radius: 6px;
        margin-right: 5px;
        cursor: pointer;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .tips-code {
      display: flex;
      margin: 20px 0;
      p {
        font-size: 12px;
        color: #848a9e;
        margin-right: 30px;
      }
      div {
        width: 168px;
        border: 1px solid #e1e4eb;
        height: 168px;
        border-radius: 12px;
       display: flex;
       align-items: center;
       justify-content: center;
      }
    }
    .tips-txt {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 9px;
      }
      p {
        font-size: 12px;
        color: #a2a6b8;
      }
    }

    // 提示
    .tips-center {
      height: 100px;
      text-align: center;
      line-height: 80px;
      font-size: 16px;
      color: #848a9e;
    }
    .tips-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    // 支付失败提示
    .tips-succse {
      .succse-top {
        margin: 0 0 15px;
        p {
          font-size: 14px;
          color: #000;
          font-weight: bold;
          padding: 0 0 5px;
        }
        span {
          font-size: 11px;
          color: #848a9e;
        }
      }
      .succse-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .succse-left {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 15px;
            margin-right: 6px;
          }
        }
      }
      .succse-bottom {
        width: 100%;
        height: 350px;
        // background: #fafafb;
        .recommend {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            position: relative;
            padding-left: 10px;
            &::before {
              content: "";
              position: absolute;
              top: 4px;
              left: 0px;
              width: 4px;
              height: 17px;
              background: #3c7efe;
              border-radius: 2px;
            }
          }
          span {
            font-size: 13px;
            color: #848a9e;
            cursor: pointer;
          }
        }
        .contant-scroll {
          width: 100%;
          height: 285px;
          overflow: hidden;
          overflow-y: scroll;
          cursor: pointer;
          &::-webkit-scrollbar {
            display: none;
          }
          .continer {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            img {
              width: 80px;
              height: 80px;
            }
            .continer-right {
              margin-bottom: 10px;
              .continer-title {
                width: 247px;
                font-size: 14px;
                font-weight: bold;
                color: #000000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              p {
                font-size: 12px;
                color: #848a9e;
                margin: 5px 0 10px;
              }
              span {
                font-size: 14px;
                font-weight: bold;
                color: #fc3838;
              }
            }
          }
        }
      }
    }

    // 身份认证
    .id-title {
      font-size: 12px;
      font-weight: bold;
      color: #000000;
    }
    .id-from {
      width: 100%;
      height: 150px;
      background: #f6f7fa;
      border-radius: 12px;
      padding-right: 56px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .id-name {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
          width: 6px;
          height: 6px;
        }
        .id-img {
          width: 14px;
          height: 15px;
          margin-left: 15px;
        }
        input {
          width: 300px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #e1e4eb;
          border-radius: 4px;
          outline: none;
          text-indent: 1em;
        }
        span {
          font-size: 14px;
          font-weight: bold;
          color: #000000;
          margin-left: 5px;
        }
      }
      .id-p {
        font-size: 12px;
        color: #426cbe;
        text-align: right;
        margin: 15px 0;
      }
    }
    .id-txt {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #a2a6b8;
      }
      span {
        margin-right: 8px;
        font-size: 16px;
        color: #a2a6b8;
        padding-top: 5px;
      }
    }
    .id-btn {
      margin: 45px 0 30px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>